import {Marker, useMap, Popup} from "react-leaflet";
import {useEffect} from "react";
import RenderPoint from "./MapSinglePoint";
import {useNavigate} from "react-router-dom";

const MapPoint = ({id, position, event, index, activePoint, setActivePoint}: any) => {
    const map = useMap();
    const navigate = useNavigate();

    useEffect(() => {
        if (activePoint === id) {
            map.setView(position);
        }
    }, [activePoint]);

    return (
        <Marker
            position={position}
            name={event.title}
            icon={RenderPoint(id, activePoint, event.bookingsPerSlot , index)}
            fill={event.bookingUrl ? '#6F48EE' : "#FF59A9"}
            borderRadius={'50%'}
            offset={[0, -20]}>

            <Popup direction="bottom" offset={[0, -20]}
                   interactive={true}
                   className="custom-tooltip"
            >
                {event.images[0] && <img src={event.images[0].url} alt={'image'}/>}
                <h1>{event.title}</h1>
                <p>{event.address}</p>
                <div>
                    <button onClick={() => navigate(`${event.id}`)}>
                        Szczegóły wydarzenia
                    </button>
                </div>
            </Popup>
        </Marker>
    );
}
export default MapPoint;

