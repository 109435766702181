import React, {ReactNode, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import validator from "validator";
import Reaptcha from "reaptcha";
import {useNavigate} from "react-router-dom";
import {registerWithEmailAndPassword} from "../../Utilities/registerWithEmailAndPassword";
import {mapError} from "../../Utilities/mapError";
import Button from "../../Components/Common/Button";
import InputField from "../../Components/Common/InputField/InputField";
import {BUTTON_STYLE} from "../../types/style";
import bg from "../../Assets/Autumn2023/bg.webp";
import {auth} from "../../config";
import {sendEmailVerification} from 'firebase/auth';
import {AuthCode} from "../../types/constants";
import {
    ContentWrapper,
    FormContainer,
    LoginTitle,
    DivButton,
    CheckboxInput,
    CheckboxText, ButtonSpan,
} from "./registerPage.styles";

function CheckboxLabel(props: { children: ReactNode }) {
    return null;
}

const RegisterPage = () => {
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [passError, setPassError] = useState<string>("");
    const [passConfirmationError, setPassConfirmationError] = useState<string>("");
    const [isAgreeChecked, setIsAgreeChecked] = useState(false);
    const captchaRef = useRef<Reaptcha | null>(null);
    const emailRef = useRef<HTMLInputElement | null>(null);
    const nameRef = useRef<HTMLInputElement | null>(null);
    const passwordRef = useRef<HTMLInputElement | null>(null);
    const passwordConfirmationRef = useRef<HTMLInputElement | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    useEffect(() => {
        if (emailRef.current?.value && nameRef.current?.value && passwordRef.current?.value && passwordConfirmationRef.current?.value && isAgreeChecked) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [emailRef.current, nameRef.current, passwordRef.current, passwordConfirmationRef.current, isAgreeChecked]);

    const navigate = useNavigate();

    useEffect(() => {
        if (window.outerWidth < 1100) {
            setIsMobile(true);
        }
    }, []);

    const verify = () => {
        if (captchaRef.current) {
            captchaRef.current.getResponse().then((res: string | null) => {
                setCaptchaToken(res);
            });
        }
    };

    const handleAgreeChange = () => {
        setIsAgreeChecked(!isAgreeChecked);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const emailValue = emailRef.current?.value || "";
        const nameValue = nameRef.current?.value || "";
        const passwordValue = passwordRef.current?.value || "";
        const passwordConfirmationValue = passwordConfirmationRef.current?.value || "";

        if (
            !validateEmail(emailValue) ||
            !validatePassword(passwordValue) ||
            !validatePasswordConfirmation(passwordConfirmationValue, passwordValue)
        ) {
            return;
        }

        try {
            registerWithEmailAndPassword(emailValue, nameValue, passwordValue, passwordConfirmationValue).then(
                (userCredentials) => {
                    if (auth.currentUser) {
                        sendEmailVerification(auth.currentUser)
                        setSuccess(true)
                    }
                }
            );

        } catch (e: any) {
            setError(mapError(e.code));
        }
    };

    const validateEmail = (email: string) => {
        if (!validator.isEmail(email)) {
            setEmailError("Podaj poprawny email");
            return false;
        } else {
            setEmailError("");
            return true;
        }
    };

    const validatePassword = (password: string) => {
        if (password.length < 8) {
            setPassError("Hasło musi mieć co najmniej 8 znaków");
            return false;
        } else if (!/[A-Z]/.test(password)) {
            setPassError("Hasło musi zawierać co najmniej jedną wielką literę");
            return false;
        } else if (!/\d/.test(password)) {
            setPassError("Hasło musi zawierać co najmniej jedną cyfrę");
            return false;
        } else if (!/[^A-Za-z0-9]/.test(password)) {
            setPassError("Hasło musi zawierać co najmniej jeden znak specjalny");
            return false;
        } else {
            setPassError("");
            return true;
        }
    };

    const validatePasswordConfirmation = (passwordConfirmation: string, password: string) => {
        if (passwordConfirmation !== password) {
            setPassConfirmationError("Hasła się nie zgadzają");
            return false;
        } else {
            setPassConfirmationError("");
            return true;
        }
    };

    return (
        <ContentWrapper isMobile={isMobile}>
            {!isMobile && <img src={bg}/>}
            {!success ? <FormContainer>
                    <form onSubmit={handleSubmit}>
                        <LoginTitle>Zarejestruj się</LoginTitle>
                        <LoginTitle>{mapError(error as AuthCode)}</LoginTitle>
                        <InputField
                            label="Twoje imię"
                            placeholder="Twoje imię"
                            type="text"
                            ref={nameRef}
                            isMobile={isMobile}
                        />
                        <InputField
                            label="Twój e-mail"
                            placeholder="Podaj swój adres e-mail"
                            type="text"
                            ref={emailRef}
                            error={emailError}
                            isMobile={isMobile}
                        />
                        <InputField
                            label="Hasło"
                            placeholder="Stwórz hasło min. 8 znaków"
                            type="password"
                            ref={passwordRef}
                            error={passError}
                            isMobile={isMobile}
                        />
                        <InputField
                            label="Powtórz hasło"
                            placeholder="Powtórz hasło"
                            type="password"
                            ref={passwordConfirmationRef}
                            error={passConfirmationError}
                            isMobile={isMobile}
                        />
                        <CheckboxLabel>
                            <CheckboxInput
                                type="checkbox"
                                id="agreeCheckbox"
                                checked={isAgreeChecked}
                                onChange={handleAgreeChange}
                            />
                            <CheckboxText>
                                Rejestrując się, potwierdzam, że akceptuję regulamin oraz politykę prywatności.
                            </CheckboxText>
                        </CheckboxLabel>
                        <Reaptcha sitekey={'6LfS_8YpAAAAAKofOBMAjF7VlixAsfvIXPSo8n0j'} ref={captchaRef}
                                  onVerify={verify}/>
                        <ButtonSpan isMobile={isMobile}>
                            {captchaToken === null ? (
                                <Button label="Zarejestruj się" disabled={!isFormValid}
                                        onClick={() => handleSubmit}
                                        buttonStyle={BUTTON_STYLE.PINK}/>
                            ) : (
                                <Button label="Zarejestruj się" disabled={!isFormValid}
                                        onClick={() => handleSubmit}
                                        buttonStyle={BUTTON_STYLE.PINK}/>

                            )}
                        </ButtonSpan>
                    </form>
                    <DivButton onClick={() => navigate('/login')}>Masz już konto? Zaloguj się</DivButton>
                </FormContainer> :
                <FormContainer>
                    <LoginTitle>Rejestracja przebiegła pomyślnie - zweryfikuj adres email by się zalogować
                    </LoginTitle>
                </FormContainer>
            }
        </ContentWrapper>
    );
};

export default RegisterPage;
