import React, {useEffect, useState} from 'react';
import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import {ImLocation} from "react-icons/im";
import dateName from "../../../Utilities/dateConverter";
import useLocation from "../../../Utilities/Hooks/useLocation";
import {Link, useNavigate, useParams} from "react-router-dom";
import BlogSlider from "../../../Pages/Blog/blogSlider";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import styled from "styled-components";
import RecenterMap from "../../../Utilities/RecenterMap";
import YellowGradient from "../../../Components/Common/YellowGradient";
import MapControlsEvent from "../../../Components/Map/MapNavActiveEvent";
import HoverableButton from "../../../Components/Common/HoverableButton";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {eventsSelector} from "../../../Store/selectors/eventsSelector";
import Button from "../../../Components/Common/Button";
import {BUTTON_STYLE} from "../../../types/style";
import {setActiveEventStore} from "../../../Store/slices/activeEventSlice";
import {EventData} from "../../../Models/event.model";
import {activeEventSelector} from "../../../Store/selectors/activeEventSelector";
import {userSelector} from "../../../Store/selectors/userSelector";
import {isMobileSelector} from "../../../Store/selectors/isMobileSelector";

const ActiveEventGdynia = () => {
    const {slug} = useParams();
    const dispatch = useAppDispatch();
    const user = useAppSelector(userSelector);
    const allEvents = useAppSelector(eventsSelector) as NonNullable<EventData[]>;
    const activeEvent = useAppSelector(activeEventSelector);
    const [sortedEvents, setSortedEvents] = useState<EventData[]>([]);
const admin = useAppSelector(userSelector)?.isAdmin;


    const position = useLocation();
    const navigate = useNavigate();
    const [prevEntryId, setPrevEntryId] = useState<string | null>(null);
    const [nextEntryId, setNextEntryId] = useState<string | null>(null);
    const [prevEntryTitle, setPrevEntryTitle] = useState<string | null>(null);
    const [nextEntryTitle, setNextEntryTitle] = useState<string | null>(null);
    const [prevEntryImage, setPrevEntryImage] = useState<string | null>(null);
    const [nextEntryImage, setNextEntryImage] = useState<string | null>(null);
    const isMobile = useAppSelector(isMobileSelector);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [slug]);

    useEffect(() => {
        if (allEvents === null) return;
        const currentEvent = allEvents.filter(ev => ev.id === slug);
        currentEvent && dispatch(setActiveEventStore(currentEvent[0]))
    }, [slug, allEvents]);


    const calculateTotalSlots = (data) => {
        let totalSlots = 0;

        data.forEach(item => {
            totalSlots += item.timeSlots.length;
        });
        return totalSlots;
    };


    function sumSpots(bookings) {
        return bookings.reduce((totalSpots, booking) => totalSpots + booking.spots, 0);
    }


    useEffect(() => {
        if (allEvents !== null && allEvents.length > 0) {

            const copy = [...allEvents]
            const sorted = copy.sort((a, b) => {
                const titleA = a.title.toUpperCase();
                const titleB = b.title.toUpperCase();

                if (titleA < titleB) {
                    return -1;
                }
                if (titleA > titleB) {
                    return 1;
                }

                return 0;
            });
            setSortedEvents(sorted)
        }
    }, [allEvents])

    useEffect(() => {
        if (sortedEvents.length > 0) {

            const currentIndex = sortedEvents.sort((a, b) => a.title - b.title).findIndex((entry) => entry.id === slug);

            if (currentIndex !== -1) {
                const prevIndex = currentIndex - 1;
                const prevEntry = sortedEvents[prevIndex];
                const nextIndex = currentIndex + 1;
                const nextEntry = sortedEvents[nextIndex];

                setPrevEntryId(prevEntry ? prevEntry.id : null);
                setNextEntryId(nextEntry ? nextEntry.id : null);
                setPrevEntryTitle(prevEntry ? prevEntry.title : null);
                setNextEntryTitle(nextEntry ? nextEntry.title : null);
                setPrevEntryImage(prevEntry?.images ? prevEntry.images[0]?.url : null);
                setNextEntryImage(nextEntry?.images ? nextEntry.images[0]?.url : null);
            }
        }

    }, [sortedEvents, slug]);

    if (!activeEvent) {
        return <div>Loading...</div>;
    }

    const MapIcon = () => {
        return L.divIcon({
            iconSize: [36, 52],
            iconAnchor: [15, 52],
            className: 'custom-div-icon',
            html: ReactDOMServer.renderToString(
                <div>
                    <YellowGradient/>
                    <ImLocation size={56} opacity={1} style={{fill: "#FFF75F"}}/>
                    <div className='circle'>{"Tu!"}</div>
                </div>
            ),
        });
    };


    const MapIcon2 = () => {
        const gradientId = 'RainbowGradient';
        const iconHtml = ReactDOMServer.renderToString(
            <div>
                <svg width="0" height="0">
                    <linearGradient id={gradientId} x1="100%" y1="100%" x2="0%" y2="0%">
                        <stop stopColor="#AF8EF5" offset="0%"/>
                        <stop stopColor="#E477F1" offset="32%"/>
                        <stop stopColor="#F19154" offset="91%"/>
                        <stop stopColor="F5C119" offset="100%"/>
                    </linearGradient>
                </svg>
                <ImLocation size={56} fill={`url(#${gradientId})`}/>
                <div className='circle'>{"Tu!"}</div>
            </div>
        );

        return L.divIcon({
            iconSize: [36, 52],
            iconAnchor: [15, 52],
            className: 'custom-div-icon',
            html: iconHtml,
        });
    };

    const description = activeEvent.description.replaceAll(' /n ', '');
    const midpoint = Math.ceil(description.length / 2 + 10);
    const splitIndex = description.indexOf(' ', midpoint);
    const firstColumnText = description.slice(0, splitIndex);
    const secondColumnText = description.slice(splitIndex + 1);

    // @ts-ignore
    return (activeEvent === null) ? <div>loading...</div> : (
        <Wrapper isMobile={isMobile}>
            <Breadcrumb aria-label="Breadcrumb" className="breadcrumb">
                <ol>
                    <li>
                        <Link to="/">
                            Strona Główna
                        </Link>
                        <span>/</span>
                    </li>
                    <li>
                        <Link to="/gdynia">
                            Gdynia
                        </Link>
                        <span>/</span>
                    </li>
                    <li>
                        <Link to="/gdynia/events">
                            Program
                        </Link>
                        <span>/</span>
                    </li>

                    {!isMobile &&
                        <li>
                            <Link to={`/gdynia/events/${activeEvent.id}`}>
                                {activeEvent.title}
                            </Link>
                        </li>}
                </ol>
            </Breadcrumb>
            {isMobile && <Breadcrumb>
                <ol>
                    <li>
                        <Link to={`/gdynia/events/${activeEvent.id}`}>
                            {activeEvent.title}
                        </Link>
                    </li>
                </ol>
            </Breadcrumb>}
            <ContentDiv isMobile={isMobile}>
                <LeftColumn isMobile={isMobile}>
                    {isMobile &&
                        <>
                            <div>
                                <h1>{activeEvent.title}</h1>
                                {/*{user && activeEvent.bookingsPerSlot && <Button label='Zarezerwuj wejście'*/}
                                {/*                                                onClick={() => navigate(`/booking/${activeEvent.id}`)}*/}
                                {/*                                                buttonStyle={BUTTON_STYLE.GREEN}/>}*/}

                            </div>
                            {!isMobile ? <DescriptionContainer isMobile={isMobile}>
                                    <div>
                                        <p>{firstColumnText.split(' /n ').map((item, idx) => {
                                            return (<span key={idx}>{item.replaceAll(' /n ', '')}<br/><br/></span>);
                                        })
                                        }</p>
                                    </div>
                                    <div>
                                        <p>{secondColumnText.split(' /n ').map((item, idx) => {
                                            return (<span key={idx}>{item.replaceAll(' /n ', '')}<br/><br/></span>);
                                        })
                                        }</p>
                                    </div>
                                </DescriptionContainer> :
                                <DescriptionContainer isMobile={isMobile}>
                                    <div>
                                        <p>{description}</p>
                                    </div>
                                </DescriptionContainer>}
                        </>}
                    {!isMobile &&
                        <BlogSlider images={activeEvent.images as NonNullable<{ url: string, label: string }[]>}
                                    width={400}/>}
                    {/*<Button label='Zarezerwuj wejście' onClick={() => navigate(`/booking/${activeEvent.id}`)}*/}
                    {/*        buttonStyle={BUTTON_STYLE.GREEN}/>*/}
                    {/*{ activeEvent.bookingsPerSlot ? */}
                    {/*    <Button label='Zarezerwuj wejście' */}
                    {/*            onClick={() => navigate(`/booking/${activeEvent.id}`)}*/}
                    {/*        buttonStyle={BUTTON_STYLE.GREEN}/>: null}*/}

                    {/*{activeEvent.bookingsPerSlot ? <Button label='START ZAPISÓW: 18.05. o 12:00'*/}
                    {/*                                               onClick={() => {*/}
                    {/*                                               }}*/}
                    {/*                                               buttonStyle={BUTTON_STYLE.GREEN}/> : null}*/}

                    {/*{activeEvent.bookingsPerSlot && activeEvent.bookingsPerSlot !== 0 ?*/}
                    {/*    <p>Liczba*/}
                    {/*        miejsc: {activeEvent.bookingsPerSlot * calculateTotalSlots(activeEvent.dateTimes) - (activeEvent.bookings?.length ? sumSpots(activeEvent.bookings) : 0)}</p>*/}
                    {/*: null}*/}

                    <h2>Gdzie?</h2>
                    <p>{activeEvent.address}</p>
                    {activeEvent.address2 && <p>{activeEvent.address2}</p>}

                    <h2>O której ?</h2>
                    <DateTimeDiv>
                        {activeEvent.dateTimes.map((d, subIndex) => (
                            <DayDiv key={subIndex}>
                                <h3>{dateName(d.date)}</h3>
                                <div>
                                    {d.timeSlots.map((timeslot, innerIndex) => (
                                        <p key={innerIndex}>{timeslot.trim()}</p>
                                    ))}
                                </div>
                            </DayDiv>
                        ))}
                    </DateTimeDiv>
                </LeftColumn>
                <RightColumn isMobile={isMobile}>

                    {!isMobile &&
                        <>
                            <h1>{activeEvent.title}</h1>
                            <DescriptionContainer isMobile={isMobile}>
                                <div>
                                    <p>{firstColumnText.split(' /n ').map((item, idx) => {
                                        return (<span key={idx}>{item.replaceAll(' /n ', '')}<br/><br/></span>);
                                    })
                                    }</p>
                                </div>
                                <div>
                                    <p>{secondColumnText.split(' /n ').map((item, idx) => {
                                        return (<span key={idx}>{item.replaceAll(' /n ', '')}<br/><br/></span>);
                                    })
                                    }</p>
                                </div>
                            </DescriptionContainer>

                            {(activeEvent.title === "08. VIDEO WOJTEK" || activeEvent.title === "78. Automobilklub Morski")
                                &&
                                <Link to={`/gdynia/events/Mvetwf3pHzW1pyrcHp73`}>
                                    Seans Christine w Automobilklubie Morskim
                                </Link>}
                        </>}
                    {isMobile &&
                        <SliderDiv>
                            <BlogSlider images={activeEvent.images as NonNullable<{ url: string, label: string }[]>}
                                        width={360}/>
                        </SliderDiv>}
                    <MapContainer id='map'
                                  center={activeEvent.position}
                                  zoom={18}
                                  maxZoom={17}
                                  minZoom={5}
                                  scrollWheelZoom={false}
                                  zoomControl={false}
                                  boxZoom={false}
                                  style={{
                                      width: isMobile ? '90vw' : '900px',
                                      height: '400px'
                                  }}>
                        <RecenterMap lat={activeEvent.position![0]} lng={activeEvent.position![1]}/>
                        <MapControlsEvent/>
                        <Marker position={activeEvent.position} icon={MapIcon()}></Marker>
                        <Marker position={position} icon={MapIcon2()}></Marker>

                        <TileLayer
                            url={'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=e87fd70e-89e4-43ef-8d2a-2b12df2d984'}/>
                    </MapContainer>


                </RightColumn>
            </ContentDiv>
            <ButtonDiv>
                <HoverableButton
                    left={true}
                    blog={false}
                    selectedCity={'gdynia'}
                    id={prevEntryId as NonNullable<string>}
                    title={prevEntryTitle as NonNullable<string>}
                    buttonImageUrl={prevEntryImage as NonNullable<string>}
                    disabled={!prevEntryId}/>
                <HoverableButton
                    left={false}
                    blog={false}
                    selectedCity={'gdynia'}
                    id={nextEntryId as NonNullable<string>}
                    title={nextEntryTitle as NonNullable<string>}
                    buttonImageUrl={nextEntryImage as NonNullable<string>}
                    disabled={!nextEntryId}/>
            </ButtonDiv>
        </Wrapper>
    )
        ;
}

export default ActiveEventGdynia

const SliderDiv = styled.div`
    display: flex;
    justify-content: center;
    width: 92vw;

`


const ContentDiv = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    justify-content: ${({isMobile}) => (isMobile ? 'center' : 'space-between')};
`

const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
`

const Wrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: ${({isMobile}) => (isMobile ? '12px' : ' 120px 40px 0px 40px')};
    gap: ${({isMobile}) => (isMobile ? '12px' : ' 24px')};

    h1 {
        color: #3E1DAA;
        font-family: Cabinet Grotesk;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    h3 {
        font-family: 'Cabinet Grotesk', sans -serif;
        font-size: 28px;
        font-weight: 400;
        padding: 0;
        margin: 0;
    }
`


const LeftColumn = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({isMobile}) => (isMobile ? '100%' : '400px')};
    gap: 10px;

    #booking {
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 60px;
        background: #E2FD5F;
        border: none;
        box-shadow: 8px 8px 0 0 #3E1DAA;
        color: #3E1DAA;
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.24px;
        width: 280px;
        margin: 20px auto;
    }

    p {
        display: flex;
        flex-direction: column;
        white-space: pre-wrap
    }

    h2 {
        color: #1B0466;
        font-family: Cabinet Grotesk;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-top: 30px;
    }

`

const RightColumn = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({isMobile}) => (isMobile ? '100%' : '900px')};
    gap: 40px;


    a {
        color: #f5f5f5;
        text-decoration: none;
        padding: 6px;
    }

    h1 {
        color: #3E1DAA;
        font-family: Cabinet Grotesk;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

`
export const Breadcrumb = styled.nav`
    a {
        text-decoration: none;
        color: #514D58;

        &:last-of-type {
            color: #1F0812;
        }
    }


    ol {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    li {
        text-decoration: none;
        color: #514D58;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        cursor: pointer;

        &:last-of-type {
            font-size: 18px;
        }
    }

    span {
        padding: 0 8px;
    }


    a:hover {
        color: #3e1daa;
    }
`

const DateTimeDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 16px;

    h3 {
        font-family: "Lato";
        font-size: 16px;
        text-transform: uppercase;
    }

    p {
        font-family: "Lato";
        font-size: 14px;
        padding-top: 6px;
    }

    @media (max-width: 500px) {
        margin-left: 10px;
        flex-wrap: wrap;

        h2 {
            font-size: 14px;
        }

        p {
            font-size: 12px;
        }
    }
`

const DayDiv = styled.div`
    width: 120px;

    div {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
`

const DescriptionContainer = styled.div<{ isMobile: boolean }>`
    display: ${({isMobile}) => (isMobile ? 'flex' : 'grid')};
    flex-direction: ${({isMobile}) => (isMobile && 'column')};
    grid-template-columns: ${({isMobile}) => (!isMobile && '1fr 1fr')};
    gap: ${({isMobile}) => (!isMobile && '16px')};
    color: #514D58;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    div {
        margin-bottom: 6px;
    }
`;
