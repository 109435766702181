import styled from "styled-components";
import React, {useEffect, useState} from "react";
import AG from "../../Assets/GdanskTeam/AG.png"
import TK from "../../Assets/GdanskTeam/TK.png"
import AL from "../../Assets/GdanskTeam/AL.png"
import DL from "../../Assets/GdanskTeam/DL.png"
import PJ from "../../Assets/GdanskTeam/PJ.png"
import MS from "../../Assets/GdanskTeam/MS.png"
import MZ from "../../Assets/GdanskTeam/MZ.png"
import GROUP from "../../Assets/GdanskTeam/GROUP.png"
import VolonteeringSection from "../Home/Component/VolounteeringSection";
import {useAppSelector} from "../../Store/hooks";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";


export function GdanskTeam() {

    const isMobile = useAppSelector(isMobileSelector);
    const team = [
        {
            name: 'Alicja Gołata',
            description: 'Współpracuje z wolontariuszami i wolontariuszkami - rekrutuje, dzieli się wiedzą i dba o dobrostan. Wspiera prace programowe, a jej specjalnością jest misterne konstruowanie gier miejskich.',
            img: AG
        },
        {
            name: 'Tomasz Kośmider',
            description: 'Kontaktuje nas z Wami. Prowadzi OHGowe kanały w mediach społecznościowych, współpracuje z mediami i patronami. Zachwyca się każdą festiwalową dzielnicą, bardzo.',
            img: TK
        },
        {
            name: 'Aneta Lehmann',
            description: 'Kuratorka gdańskiej edycji. Czyta o dzielnicy, odwiedza ją, ogląda z różnych stron, zastanawia się. Zajmuje się programem, współpracą z osobami i instytucjami partnerskimi.',
            img: AL
        },
        {
            name: 'Diana Lenart',
            description: 'Założycielka, kuratorka i producentka pierwszych polskich edycji festiwalu - Open House Gdynia i Open House Gdańsk. Serce festiwalu i nieustająca inspiracja. Dba o całe przedsięwzięcie, zajmując się literalnie wszystkim.',
            img: DL
        },
        {
            name: 'Paweł Jóźwiak',
            description: 'Uważne i wrażliwe oko festiwalu. Fotografuje dzielnice, wnętrza i osoby, które goszczą nas w każdej edycji. Spotkacie go też na festiwalowej trasie, gdzie będzie towarzyszył Waszym zachwytom i zaskoczeniom.',
            img: PJ
        },
        {
            name: 'Melania Szymerowska',
            description: 'Najlepsza producentka w branży kulturalnej i nie tylko. Planuje, koordynuje i rozlicza tak, że możemy spać spokojnie (jak również wymyślać cuda na kiju).',
            img: MS
        },
        {
            name: 'Marta Zawadzka',
            description: 'Kobieta-petarda. Współtworzy program aktywnie i intensywnie, zaraża entuzjazmem, rozwiewa ciemne chmury. Powiadają: gdzie diabeł nie może, tam Martę pośle. Bo uśmiechem przekona każdego.',
            img: MZ
        },
    ]

    return (
        <ArchiveWrapper isMobile={isMobile}>
            <Title isMobile={isMobile}>Poznaj Zespół</Title>
            <Description isMobile={isMobile}>Od kilku lat pracujemy nad festiwalem z nadzieją, że zakochacie się w
                każdej z kolejnych dzielnic, tak jak zakochujemy się w nich my. Poznajmy się!</Description>
            <ProfilesSection isMobile={isMobile}>
                {team.map((entry) =>
                    (<ProfileCard isMobile={isMobile}>
                        <img src={entry.img}/>
                        <h3>{entry.name}</h3>
                        <p>{entry.description}</p>
                    </ProfileCard>))}
            </ProfilesSection>
            <LoveSection isMobile={isMobile}>
                <Testimonials>
                    <Title isMobile={isMobile}>Za co kochamy Gdańsk?</Title>
                    <Description isMobile={isMobile}>Urodziłam się w Gdańsku i za to najbardziej go kocham. Ta dzielnica
                        jeszcze wtedy nie nazywała się Aniołki, a GUMed był Akademią Medyczną ~ Diana</Description>
                    <Description isMobile={isMobile}>Przyjechałam do Gdańska po studiach. Nowe otoczenie i pierwsza
                        praca... Nie wiedziałam, czy się odnajdę, a poszło tak gładko! Bardzo szybko poczułam się jak u
                        siebie i za to najbardziej lubię Gdańsk. Jest duży, obfity, ale nie przytłacza i pozwala znaleźć
                        swoje miejsca! ~ Ala</Description>
                    <Description isMobile={isMobile}>Gdańsk łączy w sobie wszystko, co kocham – miasto, las i morze! I
                        mimo, że tu się urodziłam i mieszkam już kilka dobrych lat, nieustannie odkrywam jego nowe
                        oblicza (również dzięki Open House Gdańsk!). ~ Marta</Description>
                    <Description isMobile={isMobile}>Za to, że jest najbardziej otwartym miastem, w którym każdy się
                        może poczuć jak u siebie! Oraz za to, że jest tak różnorodny - każda dzielnica to osobny
                        mikroświat, co staramy się pokazać w kolejnych edycjach Open House. ~ Tomek</Description>
                </Testimonials>
                <img src={GROUP}/>
            </LoveSection>
            <VolonteeringSection isMobile={isMobile}/>
        </ArchiveWrapper>
    );
}

const Testimonials = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`

const LoveSection = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    justify-content: space-around;
    align-items: center;
    padding: 80px 40px 88px 40px;
    gap: 24px;
    background: #FFA4CF;
    width: 100vw;

    img {
        width: ${({isMobile}) => (isMobile ? '280px' : '500px')};
        height: ${({isMobile}) => (isMobile ? '180px' : '370px')};
        border-radius: 200px 0 200px 0;

        h1 {
            padding: 0;
            margin: 0;
        }

        p {
            margin: 0;
            padding: 0;
        }
`


const ProfilesSection = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${({isMobile}) => (isMobile ? 'center' : '')};
    width: 100%;
    gap: 31px;
    padding-left: ${({isMobile}) => (isMobile ? '0' : '40px')};
    margin-bottom: 36px;
`

const ProfileCard = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    height: 510px;
    gap: 12px;
    width: ${({isMobile}) => (isMobile ? '90%' : '22vw')};

    img {
        width: 100%;
    }

    h3 {
        color: #1F0812;
        font-family: Arial;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%
    }

    p {
        color: #1F0812;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.16px;
    }
`


const ArchiveWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    padding: ${({isMobile}) => (isMobile ? '0' : '150px 0 50px 0')};
`;

const Title = styled.h1<{ isMobile: boolean }>`
    color: #1F0812;
    font-family: Cabinet Grotesk;
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    padding: 0;
    padding-left: ${({isMobile}) => (isMobile ? '12px' : '44px')};
    margin: 0;
`

const Description = styled.p<{ isMobile: boolean }>`
    color: #514D58;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.16px;
    width: ${({isMobile}) => (isMobile ? '90vw' : '100%')};
    padding-left: ${({isMobile}) => (isMobile ? '12px' : '40px')};
    margin: 24px 0;
`

