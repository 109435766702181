import styled from "styled-components";
import {Link} from "react-router-dom";


interface NavbarDropdownProps {
    id: string
}

const NavbarDropdown = ({id}: NavbarDropdownProps) => {
    return (
        <StyledNavbarDropdown>
            <Link to={`/${id}/`} style={{padding: 5}}>
                O festiwalu
            </Link>
            <Link to={`/${id}/events`} style={{padding: 5}}>
                Program
            </Link>
            <Link to={`/${id}/team`} style={{padding: 5}}>
                Zespół
            </Link>
        </StyledNavbarDropdown>
    )
}

export default NavbarDropdown

const StyledNavbarDropdown = styled('div')`
 display: flex;
  flex-direction: column;
    background-color: #FBFDFF;
  align-items: center;
  position: relative;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
    gap: 14px;

  a {
    color: #1F0812;: hover {
    color: #3E1DAA;
  }
  }
`

