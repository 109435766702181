import {Outlet} from 'react-router-dom';
import GdyniaPartners from "./components/GdyniaPartners";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {fetchEventsState} from "../../Utilities/fetchEvents";
import {useEffect} from "react";
import {eventsSelector} from "../../Store/selectors/eventsSelector";
import ComingSoonPage from "../comingSoonPage/comingSoonPage";
import {archiveEvents} from "../../Utilities/archiveEvents";

function Gdynia() {
    const dispatch = useAppDispatch();
    const events = useAppSelector(eventsSelector);

    useEffect(() => {
        const activeFestival = {id: "xth2h1HWUDSHXtjuIEqE"};
        fetchEventsState(dispatch, activeFestival.id);
    }, []);


    return (
        <>
            {/*<ComingSoonPage/>*/}
            {events?.length > 0 ?
            <Outlet/> : <div>loading...</div>}
            <GdyniaPartners/>
        </>
    )
}

export default Gdynia

