import {NAVBAR_LINK_ICON_POSITION, NAVBAR_LINK_STYLE} from "../../../types/style";
import styled from "styled-components";
import {Link} from "react-router-dom";
import NavbarDropdown from "./NavbarDropdown";
import {useState} from "react";
import MobileDropdown from "./MobileDropdown";

interface NavBarLinkProps {
    path: string;
    id?: string;
    navbarLinkStyle?: NAVBAR_LINK_STYLE;
    label?: string;
    icon?: string;
    altIcon?: string;
    iconPosition?: NAVBAR_LINK_ICON_POSITION;
    dropdown?: string;
    isMobile: boolean
}

const NavbarLink = ({
                        id = '',
                        path,
                        navbarLinkStyle,
                        label = '',
                        icon = '',
                        altIcon = '',
                        iconPosition = NAVBAR_LINK_ICON_POSITION.RIGHT,
                        dropdown = '',
                        isMobile,
                    }: NavBarLinkProps) => {

    const [iconColor, setIconColor] = useState<boolean>(false);

    const handleMouseEnter = () => {
        setIconColor(false); // Set the desired icon color when hovered
    };

    const handleMouseLeave = () => {
        setIconColor(false); // Reset the icon color when not hovered
    };

    return (
        <StyledNavbarLink navBarLinkStyle={navbarLinkStyle}
                          iconPosition={iconPosition}
                          dropdown={dropdown}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          isMobile={isMobile}>
            <Link to={path}>

                {iconColor
                    ? iconPosition === NAVBAR_LINK_ICON_POSITION.LEFT && <img id='alt' src={altIcon}/>
                    : iconPosition === NAVBAR_LINK_ICON_POSITION.LEFT && <img src={icon}/>}

                <span>{label}</span>

                {!isMobile
                    ? iconColor
                        ? iconPosition === NAVBAR_LINK_ICON_POSITION.RIGHT && <img id='alt' src={altIcon}/>
                        : iconPosition === NAVBAR_LINK_ICON_POSITION.RIGHT && <img src={icon}/>
                    : null}
            </Link>
            {dropdown && !isMobile &&
                <div className="dropdown-content">
                    <NavbarDropdown id={id}/>
                </div>}
            {dropdown && isMobile &&

                <MobileDropdown id={id}/>
            }
        </StyledNavbarLink>
    );
}

export default NavbarLink

const StyledNavbarLink = styled.div<{
    navBarLinkStyle?: NAVBAR_LINK_STYLE,
    iconPosition: NAVBAR_LINK_ICON_POSITION
    dropdown: string;
    isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${props => (props.isMobile) ? "column" : "row"};
  align-items: ${props => (props.isMobile) ? "left" : "center"};
  padding: ${props => (
          props.navBarLinkStyle !== NAVBAR_LINK_STYLE.PURPLE &&
          props.navBarLinkStyle !== NAVBAR_LINK_STYLE.SINGLE
  ) ? "0 16px" : "0"};
  cursor: pointer;
  margin: ${props => (props.isMobile) ? "8px 0" : "0"};

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${props => (props.isMobile) ? "Cabinet Grotesk" : "Arial"};
    font-size: ${props => (props.isMobile) ? "24px" : "18px;"};
    font-style: normal;
    font-weight: ${props => (props.isMobile) ? "700" : "400"};
    line-height: 150%;
    text-decoration: none;
  }


  ${props => (props.isMobile) ? "column" : "row"};

  span {
    color: ${props => (props.navBarLinkStyle === NAVBAR_LINK_STYLE.PURPLE) ? "#3E1DAA" : "#1F0812"};
    z-index: 3;

    :hover {
      color: ${props => (props.navBarLinkStyle !== NAVBAR_LINK_STYLE.PURPLE) ? "#3E1DAA" : "#3E1DAA"};
    }
  }

  img {
    padding-left: ${props => (props.iconPosition === NAVBAR_LINK_ICON_POSITION.RIGHT) ? "8" : "0"}px;
    padding-right: ${props => (props.iconPosition === NAVBAR_LINK_ICON_POSITION.LEFT) ? "6" : "0"}px;
    z-index: 3;
  }

  :hover {
      opacity: ${props => props.navBarLinkStyle ? 0.7 : 1};
      filter: ${props => props.navBarLinkStyle ? 4 : 1};
  }
    
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #FBFDFF;
    min-width: 120px;
    height: 140px;
    top: 25px;
    padding: 50px 10px 10px 10px;
    border-radius: 0 0 16px 16px;
    z-index: 0;
  }

  :hover .dropdown-content {
    display: block;
  }



`

