import React, {useEffect, useState} from 'react'
import {MapContainer, TileLayer, Marker} from 'react-leaflet';
import {MAP_OVERLAY, MAP_ZOOM} from '../../Constants/mapConstants.d'
import L from 'leaflet';
import * as ReactDOMServer from 'react-dom/server';
import {ImLocation} from 'react-icons/im';
import MapPoint from "./MapPoint";
import useLocation from "../../Utilities/Hooks/useLocation";
import RecenterMap from "../../Utilities/RecenterMap";
import {EventData} from "../../Models/event.model";
import MapControls from "./MapDesktopNav";
import styled from "styled-components";


interface Props {
    events: EventData[] | null
}

const MapProvider = ({events}: Props) => {

    const position = useLocation();
    const [activePoint, setActivePoint] = useState<EventData | undefined>(undefined);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const MapIcon = () => {
        const gradientId = 'RainbowGradient';

        const iconHtml = ReactDOMServer.renderToString(
            <div>
                <svg width="0" height="0">
                    <linearGradient id={gradientId} x1="100%" y1="100%" x2="0%" y2="0%">
                        <stop stopColor="#AF8EF5" offset="0%"/>
                        <stop stopColor="#E477F1" offset="32%"/>
                        <stop stopColor="#F19154" offset="91%"/>
                        <stop stopColor="F5C119" offset="100%"/>
                    </linearGradient>
                </svg>

                <ImLocation size={56} fill={`url(#${gradientId})`}/>
                <div className='circle'>{"ME!"}</div>
            </div>
        );

        return L.divIcon({
            iconSize: [36, 52],
            iconAnchor: [15, 52],
            className: 'custom-div-icon',
            html: iconHtml,
        });
    };

        return (
            <MapDiv>
                <MapContainer id='map'
                              center={position}
                              zoom={MAP_ZOOM.default}
                              maxZoom={MAP_ZOOM.max}
                              minZoom={MAP_ZOOM.min}
                              scrollWheelZoom={true}
                              zoomControl={false}
                              boxZoom={false}
                >
                    <RecenterMap lat={position[0]} lng={position[1]}/>
                    <MapControls/>
                    <Marker position={position} icon={MapIcon()}></Marker>

                    {events && events.map((point: any, index) => (
                        <MapPoint
                            position={point.position}
                            event={point}
                            key={point.id}
                            id={point.id}
                            index={index}
                            activePoint={activePoint}
                            setActivePoint={setActivePoint}
                        />
                    ))}
                    <TileLayer url={MAP_OVERLAY}/>
                </MapContainer>
                <CircleDiv>
                    <div>
                        <Circle>01</Circle>
                        <p>bez rezerwacji</p>
                    </div>
                    <div>
                        <BookingCircle>01</BookingCircle>
                        <p>rezerwacja wymagana</p>

                    </div>
                    <div>
                    <ActiveCircle></ActiveCircle>
                        <p>wybrane miejsce</p>
                    </div>
                </CircleDiv>
            </MapDiv>
        )

};

export default MapProvider


const CircleDiv = styled.div`
    display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
  
  & div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
`

const Circle = styled.div`
  display: flex;
  padding: 7px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #FF59A9;
  color: #FBFDFF;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  width: 32px;
  height: 32px;
  position: relative;
`

const BookingCircle = styled.div`
  display: flex;
  padding: 7px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #6F48EE;
  color: #FBFDFF;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  width: 32px;
  height: 32px;
  position: relative;
`


const ActiveCircle = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  padding: 7px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: none;
  color: #FBFDFF;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  width: 32px;
  height: 32px;
  

  ::before {
    width: 32px;
    aspect-ratio: 1;
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 8px;
    border-radius: 100%;
    background: linear-gradient(to right,
    rgba(175, 142, 245, 1),
    rgba(228, 119, 241, 1),
    rgba(241, 145, 84, 1),
    rgba(245, 193, 25, 1));
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

const MapDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 94vw;
  height: 120vh;
  top: 20px;
  z-index: 0;

  @media (max-width: 600px) {
    height: 50%;
    top: 72px;
  }
`
