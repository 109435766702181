import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./Store/hooks";
import {userSelector} from "./Store/selectors/userSelector";
import {useEffect, useState} from "react";
import {auth} from "./config";
import {fetchUser} from "./Utilities/fetchUser";
import {setUser} from "./Store/slices/userSlice";
import {SerializeUserData} from "./Utilities/serializeUserData";
import {fetchAllBlogArticles} from "./Utilities/fetchBlog";
import {setBlog} from "./Store/slices/blogSlice";
import Navbar from "./Components/NavBar/Navbar";
import Home from "./Pages/Home/Home";
import Blog from "./Pages/Blog/blog";
import BlogList from "./Pages/Blog/blogList";
import Post from "./Pages/Blog/post";
import Gdynia from "./Pages/GdyniaFestival/GdyniaFestival";
import GdyniaEvents from "./Pages/GdyniaFestival/GdyniaEvents";
import GdyniaFestivalDescription from "./Pages/GdyniaFestival/GdyniaFestivalDescription";
import ActiveEventGdynia from "./Pages/GdyniaFestival/components/ActiveEventGdynia";
import Gdansk from "./Pages/GdanskFestival/GdanskFestival";
import GdanskEvents from "./Pages/GdanskFestival/GdanskEvents";
import {GdanskTeam} from "./Pages/GdanskFestival/GdanskTeam";
import GdanskFestivalDescription from "./Pages/GdanskFestival/GdanskFestivalDescription";
import ActiveEvent from "./Components/FestivalView/CommonFestivalViews/ActiveEvent";
import {ArchivePage} from "./Pages/Archive/Archive";
import {FaqPage} from "./Pages/FAQ/Faq";
import BookingPage from "./Pages/BookingPage/BookingPage";
import Search from "./Pages/Search/Search";
import Login from "./Pages/login";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import UserWrapper from "./Pages/UserWrapperPage/UserWrapperPage";
import UserProfile from "./Pages/UserProfilePage/UserProfilePage";
import NoMatch from "./Pages/NotFoundPage/notFound";
import RegisterPage from "./Pages/RegisterPage/registerPage";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import Scroll from "./Components/Scroll/Scroll";
import {GdyniaTeam} from "./Pages/GdyniaFestival/GdyniaTeam";
import Rules from "./Pages/RulesPage/Rules";
import Rodo from "./Pages/RulesPage/Rodo";

function ProtectedRoute({user, children}) {
    if (!user) {
        return (
            <Navigate to="/login" replace/>
        );
    }
    return children;
}

function ProtectedRouteAdmin({isAdmin, children}) {
    if (!isAdmin) {
        return (
            <Navigate to="/" replace/>
        );
    }
    return children;
}

export function AppLayout() {

    const dispatch = useAppDispatch();
    const user = useAppSelector(userSelector);
    const [isAdmin, setAdmin] = useState(false);
    const navigate = useNavigate();

    const location = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                // if (user && user.emailVerified) {
                try {
                    const rightUser = await fetchUser(user)
                    dispatch(setUser(SerializeUserData(rightUser)));
                    setAdmin(rightUser.isAdmin);
                    navigate('/');
                    localStorage.setItem('user', JSON.stringify(SerializeUserData(user)));

                } catch (error) {
                    console.error("Error checking admin status:", error);
                }
            } else {
                setUser(null);
                setAdmin(false);
                localStorage.removeItem('user');
            }
        });
        return () => unsubscribe();
    }, []);


    useEffect(() => {
        async function fetchBlogEntries() {
            const entries = await fetchAllBlogArticles();
            dispatch(setBlog(entries));
        }

        fetchBlogEntries();
    }, []);


    return (
        <>
            <Navbar isAdmin={isAdmin}/>
            <Scroll/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/blog" element={<Blog/>}>
                    <Route index element={<BlogList/>}/>
                    <Route path=":slug" element={<Post/>}/>
                </Route>
                <Route path="/gdynia" element={<Gdynia/>}>
                    <Route index element={<GdyniaEvents/>}/>
                    <Route path="/gdynia/team" element={<GdyniaTeam/>}/>
                    <Route path="/gdynia/events" element={<GdyniaFestivalDescription/>}/>
                    <Route path="/gdynia/events/:slug" element={<ActiveEventGdynia/>}/>
                </Route>
                <Route path="/gdansk" element={<Gdansk/>}>
                    <Route index element={<GdanskEvents/>}/>
                    <Route path="/gdansk/team" element={<GdanskTeam/>}/>
                    {/*<Route path="/gdansk/events" element={<GdanskFestivalDescription/>}/>*/}
                    {/*<Route path="/gdansk/events/:slug" element={<ActiveEvent/>}/>*/}
                </Route>
                <Route path="/archive" element={<ArchivePage/>}/>
                <Route path="/faq" element={<FaqPage/>}/>
                <Route path="/booking/:slug" element={<BookingPage/>}/>
                <Route path="/search" element={<Search/>}/>
                <Route path="/rules" element={<Rules/>}/>
                <Route path="/rodo" element={<Rodo/>}/>


                //TODO: Admin Panel to be moved to routing from conditional rendering
                <Route path="/admin" element={<ProtectedRouteAdmin isAdmin={isAdmin}><AdminPanel
                    isAdmin={isAdmin}/></ProtectedRouteAdmin>}>
                    {/*    <Route path="/admin/add/Blog" element={<AddBlog/>}/>*/}
                    {/*    <Route path="/admin/add/Event" element={<AddEvent/>}/>*/}
                    {/*    <Route path="/admin/add/Festival" element={<AddFestival/>}/>*/}
                    {/*    <Route path="/admin/manage/Blog" element={<ManageBlog/>}/>*/}
                    {/*    <Route path="/admin/manage/Event" element={<ManageEvent/>}/>*/}
                    {/*    <Route path="/admin/manage/Festival" element={<ManageFestival/>}/>*/}
                </Route>

                <Route path="/login" element={<Login/>}/>
                <Route path="/forgot" element={<ForgotPassword/>}/>
                <Route path="/register" element={<RegisterPage/>}/>
                <Route path="/user" element={<ProtectedRoute user={user}><UserWrapper user={user}/></ProtectedRoute>}>
                    <Route index
                           element={<ProtectedRoute user={user}><UserProfile/></ProtectedRoute>}/>
                </Route>
                <Route path="*" element={<NoMatch/>}/>
            </Routes>
        </>
    );
}
