import React from 'react';
import styled from "styled-components";

const Rules = () => {

    return (
        <Wrapper>
            <h1>Regulamin Festiwalu Open House Gdynia</h1>
            <h2>I. INFORMACJE OGÓLNE</h2>
            <ol>
                <li> Organizatorem Festiwalu Open House Gdynia (dalej zwanego: „Festiwalem”) jest Fundacja
                    PURA z siedzibą w Gdyni, ul. Wzgórze Bernadowo 272, 81-583 Gdynia, wpisana do rejestru
                    stowarzyszeń, innych organizacji społecznych i zawodowych, fundacji oraz samodzielnych
                    publicznych zakładów opieki zdrowotnej Krajowego Rejestru Sądowego prowadzonego przez
                    Sąd Rejonowy Gdańsk – Północ w Gdańsku, VIII Wydział Gospodarczy Krajowego Rejestru
                    Sądowego, pod numerem KRS: 0000586232, NIP: 586-230-17-88, REGON: 363174776 (dalej
                    zwana: „Organizatorem”).
                </li>
                <li> Partnerami Festiwalu są przedsiębiorstwa, fundacje, stowarzyszenia i instytucje, które na
                    podstawie zawartych odrębnych umów wspierają działania Organizatora.
                </li>
                <li>Organizator udostępnia niniejszy regulamin (dalej zwany: „Regulaminem”), określający w
                    szczególności prawa i obowiązki Organizatora, w tym podległych mu osób w związku z
                    przeprowadzeniem Festiwalu, a także prawa i obowiązki Uczestników w związku z udziałem w
                    Festiwalu.
                </li>
                <li> Postanowienia Regulaminu kierowane są przede wszystkim do Uczestników, Wolontariuszy i
                    Ekspertów, ale mają odpowiednie zastosowanie również do pozostałych osób niebędących
                    Uczestnikami, Wolontariuszami lub Ekspertami, przebywających na Terenie Festiwalu, z
                    zastrzeżeniem, że nie dotyczy to osób powołanych przez Organizatora do zapewnienia
                    porządku i bezpieczeństwa w trakcie Festiwalu, w szczególności Wolontariuszy wskazanych w
                    Regulaminie.
                </li>
                <li>Osoby uczestniczące w Festiwalu w charakterze zainteresowanych zwane są dalej
                    „Uczestnikami”.
                </li>
                <li> Osoby zapewniające porządek wpuszczania zwiedzających do obiektów i bezpieczeństwo
                    Uczestnikom na Terenie Festiwalu oraz składające sprawozdania z frekwencji Festiwalu zwane
                    są dalej „Wolontariuszami”, natomiast zapoznające Uczestników z architekturą oraz historią
                    obiektu zwane są dalej łącznie „Ekspertami”.
                </li>
                <li> Terenem Festiwalu jest obszar każdej z nieruchomości udostępnianej Uczestnikom do
                    zwiedzania. Natomiast nie są nim części wspólne udostępnianych nieruchomości oraz wszelkie
                    inne miejsca wykorzystywane przez Uczestników w celu dotarcia do obiektów stanowiących
                    Teren Festiwalu, w tym chodniki, place przed nieruchomościami.
                </li>
            </ol>

            <h2>II. Festiwal</h2>
            <ol>
                <li>Festiwal Open House Gdynia jest częścią międzynarodowego festiwalu Open House Worldwide
                    organizowanego od 1992 roku w ponad 30 miastach na całym świecie. Festiwal otwiera jego
                    Uczestnikom obiekty składające się na Teren Festiwalu, w tym mieszkania prywatne,
                    rozmieszczone w różnych dzielnicach miasta. Obiekty, o których mowa w zdaniu poprzednim
                    będą udostępnione do zwiedzania w dniach od 23 do 26 maja 2024 roku. Poszczególne
                    nieruchomości zostaną otwarte dla zwiedzających w godzinach ustalonych i przedstawionych
                    Uczestnikom na stronie internetowej Festiwalu (www.openhousefestival.pl).
                </li>
                <li>Wydarzenie to promuje Miasto Gdynia poprzez przynależność do międzynarodowej organizacji
                    Open House Worldwide oraz obecność na stronie internetowej tej organizacji
                    www.openhouseworldwide.org.
                </li>
                <li>Celem Festiwalu jest kształtowanie wiedzy architektonicznej i dobrego smaku oraz
                    promowanie rodzimego rzemiosła i właściwych praktyk konserwatorskich, jak i uwrażliwianie
                    na dobre rozwiązania architektoniczne i urbanistyczne.
                </li>
                <li>Festiwal odbywa się w dniach 23-26 maja 2024 roku w Gdyni.</li>
                <li>Uczestnicy Festiwalu mają zagwarantowany darmowy wstęp do wszystkich obiektów Festiwalu
                    oraz możliwość zarezerwowania wizyty w obiektach, które są objęte obowiązkiem
                    wcześniejszej rezerwacji wizyty, z tym jednak zastrzeżeniem, że Organizator nie gwarantuje
                    dostępności miejsc dla każdego zainteresowanego Uczestnika w obiektach objętych
                    obowiązkiem wcześniejszej rezerwacji wizyty.
                </li>
                <li>Uczestnicy wpuszczani są do poszczególnych obiektów na Terenie Festiwalu w określonych
                    dniach i godzinach, w grupach, o których liczebności decyduje Organizator lub upoważnieni
                    przez niego Wolontariusze i Eksperci.
                </li>
                <li>Wolontariusze i Eksperci mają prawo do wchodzenia do wszystkich obiektów Festiwalu poza
                    kolejnością.
                </li>
                <li>Wybierając obiekty składające się na Teren Festiwalu Organizator kierował się następującymi
                    kryteriami:
                    <ol>
                        <li> Estetycznym – związanym z pięknem wynikającym z zachowania proporcji, harmonii barw,
                            stosowności i umiaru;
                        </li>
                        <li>Funkcjonalnym – uwzględniającym właściwe rozwiązania użytkowe;
                        </li>
                        <li>Szacunku – zwracającym
                            uwagę na zachowane oryginalne elementu z okresu powstania obiektu oraz remonty dokonywane w
                            poszanowaniu ducha obiektu;
                        </li>
                        <li>Jakości – podkreślającym szlachetność użytych materiałów,
                            która nie jest tożsama z ich wartością finansową;
                        </li>
                    </ol>
                </li>
            </ol>

            <h2> III. Ograniczenia </h2>
            <ol>
                <li>Zabrania się wnoszenia na Teren Festiwalu, a także posiadania przez Uczestników podczas
                    Festiwalu broni lub innych niebezpiecznych przedmiotów, materiałów wybuchowych,
                    wyrobów pirotechnicznych, materiałów pożarowo niebezpiecznych, napojów alkoholowych,
                    środków odurzających lub substancji psychotropowych.
                </li>
                <li>Zabronione jest wnoszenie lub wprowadzanie na Teren Festiwalu:
                    <ol>
                        <li>
                            zwierząt,
                        </li>
                        <li> żywności i napojów – z wyłączeniem stref gastronomicznych na terenie festiwalu
                        </li>
                        <li>jakichkolwiek ostrych lub twardych, szklanych, metalowych lub innych przedmiotów
                            mogących stwarzać niebezpieczeństwo dla Uczestników. Organizator ma prawo do odmowy wstępu
                            na teren Festiwalu osobie, w przypadkach próby takiego wniesienia lub wprowadzenia.
                        </li>
                    </ol>
                </li>

                <li>Zabrania się wnoszenia na Teren Festiwalu, a także posiadania przez Uczestników podczas
                    Festiwalu broni lub innych niebezpiecznych przedmiotów, materiałów wybuchowych,
                    wyrobów pirotechnicznych, materiałów pożarowo niebezpiecznych, napojów alkoholowych,
                    środków odurzających lub substancji psychotropowych.
                </li>

                <li>W przypadku ujawnienia wniesienia lub wprowadzenia na Teren Festiwalu rzeczy lub
                    zwierzęcia wbrew zakazowi, o którym mowa w pkt. III ppkt 2 lit. od „a” do „c” oraz pkt III
                    ppkt 3 Regulaminu, Organizator ma prawo usunąć z Terenu Festiwalu takiego Uczestnika.
                </li>

                <li>Organizator ma prawo usunąć z Terenu Festiwalu osoby, które swoim zachowaniem zakłócają porządek
                    publiczny lub zachowują się niezgodnie z Regulaminem.
                </li>
                <li>Zabrania się prowadzenia jakiejkolwiek działalności o charakterze komercyjnym, a także przeprowadzenia zbiórek pieniężnych lub prowadzenia
                    innej działalności (np. happeningów, zbierania podpisów) na Terenie Festiwalu podczas jego trwania,
                    bez uprzedniej pisemnej zgody Organizatora. Zabrania się prezentowania w trakcie trwania imprezy
                    jakichkolwiek haseł i okrzyków prowokujących lub nawołujących do zakłócenia porządku i
                    bezpieczeństwa, mogących stanowić zarzewie konfliktu, obraźliwych lub poniżających inne osoby z
                    uwagi na ich płeć, rasę, wyznanie, wiek, przekonania, poglądy polityczne, orientację seksualną,
                    przynależność do którejś z subkultur, sympatie sportowe lub z jakiegokolwiek innego powodu.
                </li>
            </ol>

            <h2> IV. Bezpieczeństwo</h2>
            <ol>
                <li> Każdy Uczestnik Festiwalu jest obowiązany zachowywać się w sposób niezagrażający
                    bezpieczeństwu innych Uczestników, Wolontariuszy, Ekspertów oraz innych osób
                    przebywających na Terenie Festiwalu, a w szczególności przestrzegać postanowień
                    Regulaminu oraz przepisów prawa, jak również bezzwłocznie zastosować się do poleceń
                    Organizatora.
                </li>
                <li>Zabronione jest tarasowanie lub zastawianie wyjść i dróg ewakuacyjnych, dróg dojazdowych
                    dla służb ratowniczych oraz hydrantów, i innych urządzeń niezbędnych w przypadku
                    prowadzenia akcji ratowniczej lub gaśniczej podczas Festiwalu.
                </li>
                <li> Uczestnik Festiwalu w razie zauważenia pożaru lub każdego innego zagrożenia dla osób i mienia na
                    terenie Festiwalu zobowiązany jest:
                    <ol>
                        <li>natychmiast powiadomić odpowiednio Organizatora lub Wolontariusza,
                        </li>
                        <li>bezwzględnie stosować się do poleceń Organizatora lub Wolontariusza,
                        </li>
                        <li> unikać wywołania paniki,</li>
                    </ol>
                </li>
                <li> nie utrudniać dojazdu służbom ratowniczym.
                </li>
            </ol>

            <h2> V. Uprawnienia i obowiązki Wolontariusza oraz Ekspertów</h2>
            <ol>
                <li>Organizator, zapewnia bezpieczeństwo Festiwalu przez cały czas jego trwania poprzez powołanie
                    Wolontariuszy i Ekspertów, reprezentujących rganizatora, na zasadach określonych w pkt. I ppkt 6
                    Regulaminu.
                </li>
                <li>Wolontariusze uprawnieni są do:
                    <ol>
                        <li>
                            sprawdzania i stwierdzania uprawnień Uczestników do uczestniczenia w Festiwalu, a w
                            przypadku stwierdzenia braku takich uprawnień – wezwania ich do opuszczenia Terenu
                            Festiwalu,
                        </li>
                        <li> przeglądania zawartości bagaży i odzieży Uczestników w przypadku podejrzenia, że osoby
                            te wnoszą lub posiadają przedmioty, w których mowa w pkt III ppkt 2 oraz ppkt 3 Regulaminu
                            lub inne
                            zagrażające bezpieczeństwu Uczestników i porządkowi Festiwalu,
                        </li>
                        <li>wydawania poleceń porządkowych Uczestnikom zakłócającym porządek publiczny lub
                            zachowującym się
                            niezgodnie z Regulaminem, a w przypadku niewykonania tych poleceń – wezwania ich do
                            opuszczenia
                            Festiwalu,
                        </li>
                        <li>ujęcia, w celu niezwłocznego przekazania Policji, Uczestników stwarzających bezpośrednie
                            zagrożenie
                            dla dóbr powierzonych ochronie oraz osób
                            dopuszczających się czynów zabronionych,
                        </li>
                        <li>legitymowania Uczestników w celu ustalenia ich tożsamości.
                        </li>
                        <li>nie wpuszczania Uczestników na teren obiektu osób innego niż polskie obywatelstwa, jeżeli
                            tak stanowi regulamin tego obiektu.
                        </li>
                    </ol>
                </li>
                <li>Czynności, o których mowa w pkt. V ppkt 2 Regulaminu, powinny być wykonywane w sposób
                    zapewniający
                    poszanowanie godności ludzkiej oraz innych dóbr osobistych osoby, w stosunku do której zostały
                    podjęte.
                </li>
                <li>Wolontariusze i Eksperci mają obowiązek zgłaszania ewentualnych problemów oraz składania raportów z
                    frekwencji Festiwalu Organizatorowi.
                </li>
                <li> Wolontariusze i Eksperci nie ponoszą odpowiedzialności za rzeczy utracone lub zagubione podczas
                    Festiwalu przez Uczestników ani za mienie Właścicieli w mieszkaniach.
                </li>
            </ol>

            <h2>VI. Uprawnienia i obowiązki Organizatora</h2>
            <ol>
                <li> Uczestnik przebywa na Terenie Festiwalu wyłącznie na własne ryzyko i odpowiada za wszelkie
                    poniesione
                    oraz wyrządzone szkody na mieniu i na osobie. Organizator w szczególności nie jest odpowiedzialny za
                    rzeczy utracone lub zagubione podczas Festiwalu przez jej Uczestnika.
                </li>
                <li>Organizator jest uprawniony do utrwalania przebiegu Festiwalu, a w szczególności zachowania osób
                    w niej uczestniczących, za pomocą urządzeń rejestrujących obraz i dźwięk. Materiały zgromadzone
                    podczas
                    utrwalania przebiegu Festiwalu, mogące stanowić dowody pozwalające na wszczęcie postępowania karnego
                    albo postępowania w sprawach o wykroczenia lub dowody mogące mieć znaczenie dla toczących się takich
                    postępowań,
                    Organizator niezwłocznie przekazuje prokuratorowi rejonowemu właściwemu ze względu na miejsce
                    przeprowadzonego Festiwalu lub właściwemu terytorialnie komendantowi powiatowemu (miejskiemu
                    rejonowemu)
                    Policji, w razie potrzeby z wnioskiem o wszczęcie postępowania karnego lub z wnioskiem o ukaranie,
                    chyba, że sam zawiadomi o przestępstwie albo wystąpi z wnioskiem o ukaranie w sprawach o
                    wykroczenia.
                </li>
                <li>Niezależnie od postanowień pkt. VI ppkt 2 Regulaminu, Organizator może również utrwalać
                    przebieg Festiwalu dla celów dokumentacji lub dla celów reklamowo – promocyjnych
                    Organizatora lub sponsorów. Organizator lub sponsor może korzystać z takiego nagrania lub
                    jego fragmentu, w tym przedstawiającego wizerunek Uczestnika lub innej osoby
                    przebywającej na Terenie Festiwalu, bez ograniczeń czasowych i terytorialnych, bez
                    konieczności zapłaty wynagrodzenia za korzystanie z wizerunku. Wykorzystanie wizerunku
                    obejmuje w szczególności utrwalenie, obróbkę, powielanie i wykorzystywanie wykonanych w
                    trakcie Festiwalu zdjęć, filmów i nagrań audio, a także publikację i przetwarzanie tych
                    materiałów w mediach elektronicznych, drukowanych, a także w dostępnych mediach
                    społecznościowych Organizatora lub sponsora. Zarejestrowany wizerunek będzie
                    przetwarzany przez Organizatora zgodnie z treścią Informacji o przetwarzaniu danych
                    osobowych (Polityki prywatności).
                </li>
                <li>Organizator nie odpowiada za zapewnienie zaplecza higieniczno-sanitarnego. </li>
                <li>Organizator zastrzega sobie prawo do modyfikacji programu oraz odwołania Festiwalu z
                    przyczyn od niego niezależnych, w tym w przypadku wystąpienia siły wyższej. Organizator nie
                    będzie wobec Uczestników zobowiązany do żadnej rekompensaty lub odszkodowania z tego
                    tytułu.
                </li>
                <li>Organizator nie ponosi odpowiedzialności za brak możliwości udostępnienia lub obejrzenia obiektu
                    przez Uczestnika.
                </li>
            </ol>


            <h2>VII. Uprawnienia i obowiązki Uczestników</h2>
            <ol>
                <li> Uczestnicy Festiwalu mają obowiązek poszanowania własności prywatnej, stosowania się do
                    poleceń Wolontariuszy i Organizatorów oraz Ekspertów i Właścicieli/Zarządców obiektów
                    stanowiących Teren Festiwalu.
                </li>
                <li>Uczestnicy Festiwalu mają obowiązek poruszania się wyłącznie po wyznaczonych miejscach obiektu
                    wydzielonych za pomocą ścian działowych, taśm, tablic informacyjnych lub w jakikolwiek inny
                    sposób w tym poprzez słowne określenie wyznaczonych i udostępnionych miejsc obiektu.
                </li>
                <li>W przypadku dokonania przez Uczestnika rezerwacji wizyty w danym obiekcie, ma on obowiązek
                    przedstawienia odpowiedniego dokumentu potwierdzającego swoją tożsamość.
                </li>
                <li>Wstęp na Teren Festiwalu możliwy jest wyłącznie dla osób pełnoletnich. Warunkiem wpuszczenia
                    małoletniej osoby do 16 roku życia na Teren Festiwalu jest przebywanie tej osoby pod opieką osoby
                    pełnoletniej przez cały okres trwania Festiwalu.
                </li>
                <li>Uczestnik ma prawo do zadawania pytań Ekspertom i Wolontariuszom podczas trwania Festiwalu w
                    sposób
                    nie zakłócający procesu prezentowania przez Eksperta charakterystyki danego obiektu.
                </li>
                <li> Uczestnik ma prawo do utrwalania przebiegu Festiwalu oraz obiektów, za pomocą urządzeń
                    rejestrujących obraz i dźwięk, po uprzednim wyrażeniu zgody przez Eksperta lub Wolontariusza, w
                    przypadku lokalizacji w której utrwalanie nie będzie możliwe ze względu na zalecenia i regulamin
                    obiektu, Uczestnicy zostaną o tym poinformowani i zostanie zebrana lista z podpisami o zapoznaniu
                    się z ograniczeniami.
                </li>
                <li> Ochrona ubezpieczeniowa z tytułu odpowiedzialności cywilnej oraz ewentualnych szkód obejmuje
                    zdarzenia mające miejsce wyłącznie wewnątrz poszczególnych obiektów. Organizatorzy nie
                    odpowiadają za szkody mające miejsce w pomieszczeniach pomiędzy obiektami (m.in. klatki
                    schodowe). Ubezpieczeni są również Wolontariusze z tytułu NNW.
                </li>
            </ol>

            <h2> VIII. Postanowienia końcowe </h2>
            <ol>
                <li>Ambicją Festiwalu Open House Gdynia jest wskazywanie cech i elementów charakterystycznych dla danej
                    architektury i danego okresu historycznego dla budowania tożsamości lokalnej.
                </li>
                <li> Myślą przewodnią Festiwalu jest nauczanie poszanowania dla substancji zastanej i dla ducha
                    obiektu wpisanego w kontekst miejsca oraz pobudzenie potrzeby życia w schludnym, zadbanym i
                    harmonijnym
                    miejscu.
                </li>
                <li>Rekomendacją jest zachowanie tożsamości kulturowej (antyglobalizacja w tym zakresie) i wskazanie
                    tego, co można zrobić, żeby zachować istniejące i połączyć je z nowym (nowymi elementami wystroju
                    lub przestrzeni) w inteligentny sposób (rozwiązanie niskobudżetowe).
                </li>
                <li>Inicjatywą Festiwalu Open House Gdynia jest kojarzenie mieszkańców (użytkowników różnych
                    obiektów) z osobami, które z racji swojej wiedzy, zainteresowań, talentów mogą wesprzeć ich w
                    realizacji zamierzenia jakim jest harmonijne i niskokosztowe miejsce zamieszkania (architekci,
                    rzemieślnicy, artyści).
                </li>
                <li>Aspiracją Organizatorów jest poruszenie sumienia właścicieli kamienic, mieszkań i innych obiektów i
                    w konsekwencji zmiana wizerunku miasta.
                </li>
                <li> Przetwarzanie przez Organizatora informacji stanowiących dane osobowe Uczestników,
                    Wolontariuszy, Ekspertów oraz innych osób odbywa się zgodnie z postanowieniami
                    Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/69 z dnia 27 kwietnia 2016 r. w
                    sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                    swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
                    o ochronie danych) oraz zgodnie z przepisami ustawy z 10 maja 2018 roku o ochronie danych
                    osobowych (Dz.U.2019.1781 t.j.).
                </li>
                <li>Regulamin wchodzi z dniem ogłoszenia i obowiązuje do momentu oficjalnego zakończenia Festiwalu
                    przez Organizatora.
                </li>
                <li>Organizator zastrzega sobie prawo do zmiany treści niniejszego Regulaminu w dowolnym czasie
                    poprzez aktualizację jego treści na stronie internetowej Organizatora.</li>
                <li>Udział w Festiwalu jest jednoznaczny z akceptacją niniejszego Regulaminu.</li>
<li>Wszelkie spory mogące wyniknąć z tytułu uczesnictwa w Festiwalu będą rozstrzygane przez sąd
    właściwy miejscowo dla siedziby Organizatora.</li>
                <li>W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie mają przepisy powszechnie
                    obowiązującego prawa, w szczególności przepisy Kodeksu cywilnego.</li>
            </ol>
        </Wrapper>
    );
}

export default Rules

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: black;;
    font-family: 'Lato', sans-serif;
    margin: 5%;
    margin-top: 130px;

    h1 {
        color: #1F0812;
        font-family: Cabinet Grotesk;
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 50px;
    }

    h2 {
        color: #1F0812;
        font-family: Cabinet Grotesk;
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 24px;
    }

    li {

    }

    ol {
        margin-bottom: 30px;
    }
`
