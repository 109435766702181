import styled from "styled-components";
import {Link} from "react-router-dom";


interface MobileDropdownProps {
    id: string
}

const MobileDropdown = ({id}: MobileDropdownProps) => {
    return (
        <StyledMobileDropdown id="dropdown-link">
            <Link to={`/${id}/archive`} id="dropdown-link">
                O festiwalu
            </Link>
            <Link to={`/${id}/events`} id="dropdown-link">
                Program
            </Link>
            <Link to={`/${id}/team`}  id="dropdown-link" >
                Zespół
            </Link>
        </StyledMobileDropdown>
    )
}

export default MobileDropdown

const StyledMobileDropdown = styled('div')`
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-left: 30px;

  #dropdown-link {
    color: #1F0812;
    font-family: Cabinet Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-decoration: none;
    padding: 10px 16px;

    :hover {
      color: #3E1DAA;
    }
  }
`

