import {BORDER_STYLE, BUTTON_STYLE} from "../../../types/style";
import Button from "../../../Components/Common/Button";
import {
    ButtonDiv,
    DescriptionDiv,
    EmptyCircle,
    LazyImage,
    StyledVolonteeringSection
} from "./VolounteeringSection.styles";

interface VolonteeringSectionProps {
    isMobile: boolean
}

function VolonteeringSection({isMobile}: VolonteeringSectionProps) {

    return (
        <StyledVolonteeringSection isMobile={isMobile}>
                {!isMobile && <LazyImage borderStyle={BORDER_STYLE.BOTTOM_LEFT}>
                    <img src='https://storage.cloud.google.com/openhouse_trojmaisto/wolo-img.png' alt='volunteers'/>
                </LazyImage>
                }
                <DescriptionDiv isMobile={isMobile}>
                    <h3>Chcesz współtworzyć to wydarzenie?</h3>
                    <p>
                        Wolontariat OH Gdynia i OH Gdańsk to spotkanie z miastem “od kuchni”, wielka przygoda i nowe
                        znajomości. Open House przedstawia mieszkańców dzielnic, pozwala na słuchanie ich opowieści i
                        dzielenie się doświadczeniami.
                    </p>
                    <p>
                        Chcesz współtworzyć to wydarzenie? Zapisz się do wolontariatu OH Gdynia (wiosna) lub OH Gdańsk
                        (jesień). Udział w organizowanych przez nas festiwalach, to wiele korzyści i radości:
                        poszerzanie
                        wiedzy architektonicznej i urbanistycznej, współpraca z doświadczonymi zespołami, szkolenia,
                        sieć
                        kontaktów branżowych i nowych znajomości, zaświadczenia o wolontariacie, a przede wszystkim
                        miejska
                        przygoda!
                    </p>
                </DescriptionDiv>
                <ButtonDiv isMobile={isMobile}>
                    {!isMobile && <EmptyCircle index={1}/>}
                    {!isMobile && <EmptyCircle index={2}/>}
                    {!isMobile && <EmptyCircle index={3}/>}
                    <Button label='Zapisz się na wolontariat' onClick={() => window.location.href = `mailto:wolobtariat@openhousefestival.pl?subject=wolontariat`}
                            buttonStyle={BUTTON_STYLE.PINK}/>
                </ButtonDiv>

        </StyledVolonteeringSection>
    );
}

export default VolonteeringSection;

