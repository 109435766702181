import DeleteEventModal from "./Messages/DeleteEventModal";
import EditEvent from "./EditEventModal";
import {setDeleteEventModal} from "../../Store/slices/deleteEventModalSlice";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import React, {useEffect, useState} from 'react';
import {EventData} from "../../Models/event.model";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {deleteEventModalSelector} from "../../Store/selectors/deleteEventModalSeletor";
import styled from "styled-components";
import updateIsEventVisible from "../../Utilities/updateEventVisible";
import {fetchAllEventsState} from "../../Utilities/fetchEvents";
import CollapsibleEvent from "../../Components/Common/CollapsibleEvent";
import dateName from "../../Utilities/dateConverter";
import deleteBookingById from "../../Utilities/deleteBookingById";

interface Props {
    e: EventData,
}

const EventBox = (props: Props) => {
    const {e} = props;
    const dispatch = useAppDispatch();
    const [openEditEvent, setOpenEditEvent] = useState<string | null>(null);
    const deleteEventModal = useAppSelector(deleteEventModalSelector)

    const toggler = async (id, visibility) => {
        await updateIsEventVisible(id, visibility)
        await fetchAllEventsState(dispatch);
    }

    const deleteBooking = async (eventId, bookings, index) => {
        await deleteBookingById(eventId, bookings, index)
        await fetchAllEventsState(dispatch);

    }

    useEffect(() => {
        if (openEditEvent !== null || deleteEventModal === e.id) {
            window.scrollTo(0, 0);
        }
    }, [openEditEvent, deleteEventModal]);

    function async(): React.MouseEventHandler<HTMLButtonElement> | undefined {
        throw new Error("Function not implemented.");
    }

    return (
        <div key={e.id}>
            {deleteEventModal === e.id && <DeleteEventModal id={e.id}/>}
            {openEditEvent === e.id && <EditEvent
                event={e}
                openEditEvent={openEditEvent}
                setOpenEditEvent={setOpenEditEvent}
            />}
            <CollapsibleEvent event={e}>
                <CollapsibleChildren>
                    <CollapsibleInfo>
                        <>
                            <p><span>Wydarzenie :</span> {e.title}</p>
                            <p><span>Opis:</span> {e.description}</p>
                            <p><span>Adres :</span> {e.address}</p>
                            <h2>Daty:</h2>
                            <DateTimeDiv>
                                {e.dateTimes.map((d, subIndex) => (
                                    <DayDiv key={subIndex}>
                                        <h2>{dateName(d.date)}</h2>
                                        <div>
                                            {d.timeSlots.map((timeslot, innerIndex) => (
                                                <p key={innerIndex}>{timeslot}</p>
                                            ))}
                                        </div>
                                    </DayDiv>
                                ))}
                            </DateTimeDiv>

                            <p><span>Ilość miejsc per slot :</span> {e.bookingsPerSlot}</p>
                            <p><span>Ilość miejsc na rezerwacje :</span> {e.bookingsPerUser}</p>
                            <h2>Rezerwacje: {e.bookingsPerSlot === 0 ? 'wydarzenie bez rezerwacji' : ''}</h2>
                            {e.bookings?.length && <DateTimeDiv>
                                {e.bookings?.map((booking, subIndex) => (
                                    <DayDiv key={subIndex}>
                                        <h4>{subIndex + 1}</h4>
                                        <p>email: {booking.userName} - {booking.userEmail}</p>
                                        <p>data: {booking.date} - {booking.timeSlot}</p>
                                        <p>ilość miejs: {booking.spots}</p>
                                        <button onClick={async () => {
                                                await deleteBooking(e.id, e.bookings, subIndex)
                                            }}>Anuluj
                                        </button>
                                    </DayDiv>
                                ))}
                            </DateTimeDiv>
                            }

                        </>
                    </CollapsibleInfo>

                    <ButtonCollapse>
                        <button onClick={() => {
                            setOpenEditEvent(e.id)
                        }}>Edytuj
                        </button>
                        <button onClick={() =>
                            dispatch(setDeleteEventModal(e.id))
                        }>Usuń
                        </button>
                        <button onClick={async () => {
                            await toggler(e.id, e.isVisible)
                        }}>
                            {e.isVisible === true ? <MdVisibility/> : <MdVisibilityOff/>}
                        </button>
                    </ButtonCollapse>
                </CollapsibleChildren>
            </CollapsibleEvent>
        </div>
    )
}


const CollapsibleChildren = styled.div`
    display: flex;
    flex-direction: row`

const CollapsibleInfo = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    span {
        font-weight: 600;
    }
`

const ButtonCollapse = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
`

const DateTimeDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 600px;

    h2 {
        font-family: "Lato";
        font-size: 16px;
        text-transform: uppercase;
    }

    p {
        font-family: "Lato";
        font-size: 14px;
        padding-top: 6px;
    }

`

const DayDiv = styled.div`
    width: 400px;

    div {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
`


export default EventBox
