import styled, {keyframes} from "styled-components";
import {BiChevronLeft} from "react-icons/bi";
import {useNavigate} from "react-router-dom";
import {setIsVisible} from "../../Store/slices/isVisibleSlice";
import {IS_VISIBLE, SELECTED_CITY} from "../../types/constants";
import {useAppDispatch} from "../../Store/hooks";
import {setSelectedCity} from "../../Store/slices/selectedCitySlice";
// import CURRENT_GDA from "../../Assets/Autumn2023/currentGda.png";
// import GDA3 from "../../Assets/Autumn2023/GDA3.png";
// import GDA4 from "../../Assets/Autumn2023/GDA4.png";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";
import GdanskPartners from "./components/GdanskPartners";
import {useEffect, useState} from "react";

function GdanskEvents() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.outerWidth < 1200) {
            setIsMobile(true);
        }
    }, []);

    const handleClose = () => {
        navigate("/")
        setTimeout(() => {
            dispatch(setIsVisible(IS_VISIBLE.BOTH));
            dispatch(setSelectedCity(SELECTED_CITY.NONE))
        })
    }

    return (
        <Wrapper isMobile={isMobile}>
            <div>
                <StyledSmallCircle onClick={handleClose} isMobile={isMobile}>
                    <AnimatedChevronLeft color={"#FBFDFF"} size={"66px"}/>
                    {/*<DarkCircle isMobile={isMobile}>*/}
                    {/*    <img src={CURRENT_GDA} alt='Current Festival Identification'/>*/}
                    {/*</DarkCircle>*/}
                </StyledSmallCircle>
                {/*<FestivalCoverImage isMobile={isMobile}>*/}
                {/*    <img src={GDA3} alt=''/>*/}
                {/*</FestivalCoverImage>*/}
                {/*<FestivalSmallImage isMobile={isMobile}>*/}
                {/*    <img src={GDA4} alt=''/>*/}
                {/*</FestivalSmallImage>*/}
                <FestivalTitleDiv isMobile={isMobile}>
                    <h1>Gdańsk</h1>
                    <div>
                        <h2>29.09 – 01.10.2023</h2>
                        <Bullet isMobile={isMobile}>Zaspa</Bullet>
                    </div>
                </FestivalTitleDiv >
            </div>
            <ContentDiv isMobile={isMobile}>
                <DescriptionDiv isMobile={isMobile}>
                    <Title isMobile={isMobile}>
                        <h2>O festiwalu</h2>
                    </Title>
                    <Text isMobile={isMobile}>
                        <p>Patrząc na mapę, widzimy zieleń przeplatającą się z silnymi, geometrycznymi formami.
                            Przestrzenne układy przypominające plastry miodu. Wszystko to przebite wyrazistą osią.</p>
                        <p>Te plastry miodu to osiedle mieszkaniowe. Zaprojektowane z dbałością o podstawowe, codzienne
                            potrzeby. Uwzględniające usługi i edukację, zieleń, przestrzenie do zabawy i spotkań.
                            Mikroświaty w makrostrukturze.</p>
                        <span>Zaspa – bloki, murale, dawne lotnisko. Ale jaka naprawdę jest Zaspa? Kim są jej mieszkańcy
                            i mieszkanki? Kto i co mości się między wielkimi płytami?</span>
                        <p>Na te pytania odpowiedzi poszukamy w trakcie szóstej edycji Open House Gdańsk, przemierzając
                            i Rozstaje, i Młyniec.</p>
                        <p>W zdominowanych przez echa modernizmu przestrzeniach poszukamy stempli współczesności.
                            Zobaczymy jak nowo powstające budynki mają się do architektury, która stworzyła Zaspę w jej
                            najbardziej charakterystycznym wydaniu. Zajrzymy do przestrzeni dużych i małych – od hal i
                            kościołów po rzemieślnicze pracownie. Na murale postaramy się spojrzeć z perspektywy
                            zupełnie nieoczywistej. Zaprosimy Was do miejsc, w których gości taniec, malarstwo,
                            ceramika. Będzie też o prozie życia i o tym, jak bardzo potrafi inspirować. Choć trzeba
                            przyznać, że trudno mówić o zwyczajnej codzienności w miejscu, które gościło i noblistę, i
                            papieża.</p>
                        <p>To będzie najbardziej geometryczna edycja OHG. I jakże inna niż dotychczas - po raz pierwszy
                            zanurzymy się w obszarze, który w przeważającej większości jest jednorodnym, potężnym
                            osiedlem.</p>
                        <Button
                            buttonStyle={BUTTON_STYLE.GREEN}
                            onClick={()=>navigate("/gdansk/events")}
                            label='Przejdź do wydarzeń'/>
                    </Text>
                </DescriptionDiv>
            </ContentDiv>
        </Wrapper>
    );
}

export default GdanskEvents

const Wrapper = styled.div<{isMobile: boolean}>`
  height: 1800px;
  width: ${({isMobile}) => (isMobile ? '100vw' : '1440px')};
  overflow-x: hidden;
  
  button{
    margin: 80px 0 40px 0;
    width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
      margin-bottom: 0;
    }
  }
`
const appear = keyframes`
  0% {
   opacity: 0
  }
  
  50% {
    opacity: 1;
  }
  
`;



const DarkCircle = styled.div<{isMobile: boolean}>`
  display: flex;
  height: ${({isMobile}) => (isMobile ? '70px' : '98px')};
  width: ${({isMobile}) => (isMobile ? '70px' : '98px')};
  background-color: #1F0812;
  position: absolute;
  border-radius: 50%;
  transition: 0.4s ease-in-out;
  z-index: 3;
  overflow-x: hidden;
  right: 5px;
  align-items: center;
  justify-content: center;
  animation: ${appear} 0.3s ease-in;

  img {
    position: relative;
    max-height: 80%;
  }
`

const FestivalCoverImage = styled.div<{isMobile: boolean}>`
  border-radius: 0 0 200px 0;
  position: absolute;
  opacity: 1;
  transition: 0.5s ease-in-out;
  right: ${({isMobile}) => (isMobile ? '0' : '8vw')};
  top: ${({isMobile}) => (isMobile ? '85px;' : '105px')};
  width: ${({isMobile}) => (isMobile ? '98vw;' : '60vw')};
  height:  ${({isMobile}) => (isMobile ? '40vh;' : '28vw')};
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  
  img {
    width: ${({isMobile}) => (isMobile ? '98%;' : '60vw')};
    height:  ${({isMobile}) => (isMobile ? '40vh;' : '28vw')};
    object-fit: cover;
    border-radius: 0 0 200px 0;
  }
`
const FestivalSmallImage = styled.div<{isMobile: boolean}>`
  width: ${({isMobile}) => (isMobile ? '128px' : '315px')};
  height: ${({isMobile}) => (isMobile ? '128px' : '315px')};
  border-radius: 50%;
  position: absolute;
  transform: translateX(0);
  opacity: 1;
  transition: 0.5s ease-in-out;
  bottom: ${({isMobile}) => (isMobile ? '138px' : '30px')};
  left: ${({isMobile}) => (isMobile ? '188px' : '322px')};

  @media(min-width: 1600px){
    bottom: 120px;
  }
  
  img {
    position: relative;
    width: ${({isMobile}) => (isMobile ? '128px' : '315px')};
    height: ${({isMobile}) => (isMobile ? '128px' : '315px')};
    object-fit: cover;
    border-radius: 50%;
    z-index: 20;
  }

  &::after {
    opacity: 1;
    content: "";
    position: fixed;
    border: 2px solid #FF59A9;
    border-radius: 50%;
    width: ${({isMobile}) => (isMobile ? '128px' : '315px')};
    height: ${({isMobile}) => (isMobile ? '128px' : '315px')};
    left: ${({isMobile}) => (isMobile ? '20px' : '60px')};
    z-index: 1;
  }

  &::before {
    opacity: 1;
    content: "";
    position: fixed;
    border: 2px solid #FF59A9;
    border-radius: 50%;
    width: ${({isMobile}) => (isMobile ? '128px' : '315px')};
    height: ${({isMobile}) => (isMobile ? '128px' : '315px')};
    left: ${({isMobile}) => (isMobile ? '40px' : '120px')};
    z-index: 1;
  }`


const FestivalTitleDiv = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  width: ${({isMobile}) => (isMobile ? '94%' : '458px')};
  max-height: 200px;
  position: absolute;
  opacity: 1;
  transition: 0.5s ease-in-out;
  bottom: ${({isMobile}) => (isMobile ? '-70px' : '30px')};
  right: ${({isMobile}) => (isMobile ? '0' : '80px')};
  overflow-x: hidden;

  @media (min-width: 1600px) {
    bottom: 120px;
  }

  h1 {
    color: #3E1DAA;
    font-family: Cabinet Grotesk, sans-serif;
    font-size: ${({isMobile}) => (isMobile ? '70px' : '140px')};
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 140px */
    letter-spacing: -1.4px;
    margin: 0;
  }

  h2 {
    color: #3E1DAA;
    font-family: Cabinet Grotesk,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin: 0;
  }

  & > div {
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    justify-content: space-between;
    align-items: ${({isMobile}) => (isMobile ? 'left' : 'center')};
  }
`

const Bullet = styled.div<{isMobile: boolean}>`
  color: #3E1DAA;
  font-family: Arial,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 60px;
  background: #FCE36E;
  overflow-x: hidden;
  max-width: 200px;
`

const StyledSmallCircle = styled.div<{isMobile: boolean}>`
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: ${({isMobile}) => (isMobile ? '80px' : '110px')};
  padding: 0 0 0 20px;
  width: ${({isMobile}) => (isMobile ? '140px' : '190px')};
  background-color: #3E1DAA;
  border-radius: 360px;
  display: flex;
  opacity: 1;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  position: absolute;
  top: ${({isMobile}) => (isMobile ? '360px' : '420px')};
  left: ${({isMobile}) => (isMobile ? '20px' : '40px')};
  overflow-x: hidden;

  
  @media(min-width: 1600px){
    transform: translateX(6.8vw);
  }
`;

const slideLeftRight = keyframes`
  0% {
    transform: translateX(-10px); /* Move left */
  }
  50% {
    transform: translateX(10px); /* Move right */
  }
  100% {
    transform: translateX(-10px); /* Move left again */
  }
`;

const AnimatedChevronLeft = styled(BiChevronLeft)`
  animation: ${slideLeftRight} 2s infinite; /* 2 seconds duration, repeat infinitely */
`;

const Text = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  color: #1F0812;
  width: ${({isMobile}) => (isMobile ? '97%' : '785px')};
  font-family: Arial,sans-serif;

  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 32px 0;

  }

  span {
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 32px 0;
  }
`

const Title = styled.div<{isMobile: boolean}>`
  width: ${({isMobile}) => (isMobile ? '90%' : '37%')};

  h2 {
    color: #3E1DAA;
    font-family: Cabinet Grotesk,sans-serif;
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-align: ${({isMobile}) => (isMobile ? 'left' : 'right')};
    padding-right: ${({isMobile}) => (isMobile ? '0' : '35px')};
  }
`

const DescriptionDiv = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  gap: 20px;
  width:  ${({isMobile}) => (isMobile ? '100%' : '80%')};
  margin: 0 auto;
  overflow-x: hidden;
`
const ContentDiv = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  padding: ${({isMobile}) => (isMobile ? '40px 12px' : '70px 40px')};
  position: absolute;
  top: 95vh;
  width: ${({isMobile}) => (isMobile ? '94%' : '1440px')};
  overflow-x: hidden;

@media(max-width: 1280px){
  width: ${({isMobile}) => (isMobile ? '94%' : '1280px')};
}
`
