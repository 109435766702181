import {
    Outlet
} from 'react-router-dom';
import GdanskPartners from "./components/GdanskPartners";
import ComingSoonPage from "../comingSoonPage/comingSoonPage";

function Gdansk() {
    return (
        <>
            <ComingSoonPage/>

            {/*<Outlet/>*/}
            {/*<GdanskPartners/>*/}
        </>
    );
}

export default Gdansk
