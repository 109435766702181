import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {userSelector} from "../../Store/selectors/userSelector";
import visibility from "../../Assets/visibility.svg";
import {auth} from "../../config";
import {GrFormClose} from "react-icons/gr";
import {FiEdit2} from "react-icons/fi";
import updateUserData from "../../Utilities/updateUserData";
import {fetchUser} from "../../Utilities/fetchUser";
import {sendPasswordReset} from "../../Utilities/sendPasswordReset";
import {BookingDiv, DivClickable, InputDiv, SuccessDiv, UserDataDiv} from './UserProfilePage.styles';
import {DivButton} from "../login";
import {eventsSelector} from "../../Store/selectors/eventsSelector";
import {fetchAllEventsState} from "../../Utilities/fetchEvents";
import EventTile from "../GdyniaFestival/components/EventTile";
import {EventData} from "../../Models/event.model";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";
import styled from "styled-components";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";

export const UserProfilePage = () => {

    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(userSelector);
    const user = (auth.currentUser as NonNullable<any>)
    const events = useAppSelector(eventsSelector);
    const [changeFirstName, setChangeFirstName] = useState(false);
    const [userFirstName, setUserFirstName] = useState('');
    const [userEmailAddress, setUserEmailAddress] = useState('');
    const [userId, setUserId] = useState(user.id || '');
    const [success, setSuccess] = useState(false);
    const [userEvents, setUserEvents] = useState<EventData[]>([]);
    const isMobile = useAppSelector(isMobileSelector);


    useEffect(() => {
        fetchAllEventsState(dispatch);
    }, [events, dispatch]);

    useEffect(() => {
        if (events) {
            setUserEvents(events?.filter(event => event.bookings?.some(booking => booking?.userUid === user.uid)))
        }
    }, [events]);


    useEffect(() => {
        if (currentUser) {
            setUserFirstName(currentUser.firstName);
            setUserEmailAddress(currentUser.email);
        }
    }, [currentUser])


    if (!events?.length) {
        return <div>Loading...</div>
    }

    return (
        <Wrapper isMobile={isMobile}>
            <h1>Cześć {user?.firstName ? user?.firstName : user?.displayName}!</h1>
           <ContentWrapper isMobile={isMobile}>
               <BookingDiv isMobile={isMobile}>
                   <h3>MojeRezerwacje</h3>
                   {userEvents && userEvents?.length
                       ? <div>
                           {userEvents?.map((userEvent, index) => (
                               <div key={index}>
                                   <EventTile event={userEvent} isMobile={isMobile} deleteButton={true}/>
                               </div>
                           ))}
                       </div>
                       : <div>
                           <img src={visibility} alt=''/>
                           <h4>Trochę tu pusto!</h4>
                           <p>Tu znajdziesz wszystkie wydarzenia,na które zarezerwujesz wejście.</p>
                       </div>
                   }
               </BookingDiv>
               <UserDataDiv isMobile={isMobile}>
               {success ? (<SuccessDiv onClick={() => {
                    setSuccess(false)
                }}>{'Dane uaktualnione poprawnie.'}<span><GrFormClose/></span></SuccessDiv>) : null}
                   <InputDiv isMobile={isMobile}>
                       <h3>MojeDane</h3>
                       <label>Imię</label>
                       <h3>{userFirstName}</h3>
                       {/*{changeFirstName ? (*/}
                       {/*    <form className='name-form'>*/}
                       {/*        <input type='text'*/}
                       {/*               onChange={(el: { target: { value: React.SetStateAction<string>; }; }) => {*/}
                       {/*                   setUserFirstName(el.target.value);*/}
                       {/*               }} onBlur={() => setChangeFirstName(false)}*/}
                       {/*               value={userFirstName}/>*/}
                       {/*    </form>) : (*/}
                       {/*    <DivClickable onDoubleClick={() => setChangeFirstName(true)}>*/}
                       {/*        <span>{userFirstName}</span>*/}
                       {/*        <FiEdit2/>*/}
                       {/*    </DivClickable>)*/}
                       {/*}*/}
                   </InputDiv>
                   <InputDiv isMobile={isMobile}>
                   <label>Adres email konta</label>
                        <h3>{userEmailAddress}</h3>
                </InputDiv>
               {/*<Button label={'Zmień imię'} onClick={async(e)=>{*/}
               {/*   e.preventDefault();*/}
               {/*    await updateUserData(userId, userFirstName)*/}
               {/*    setSuccess(true);*/}
               {/*    const newData = await fetchUser(user);*/}
               {/*}} buttonStyle={BUTTON_STYLE.GREEN}/>*/}

                <DivButton onClick={async () => {
                    await sendPasswordReset(userEmailAddress)
                }}>
                    Chcesz zmienić hasło? Kliknij - wyślemy Ci do tego linka
                </DivButton>
            </UserDataDiv>
           </ContentWrapper>
        </Wrapper>
    );
}

export default UserProfilePage

const Wrapper = styled.div<{isMobile: boolean}>`
    display: flex;
    flex-direction: column;
    padding: ${props => (props.isMobile ? '0' : '120px 40px')};
    
    h1{
        color: #3e1daa;
        font-family: Cabinet Grotesk;
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        padding: 0;
        margin: 0 0 44px 0 ;
    }
`

const ContentWrapper = styled.div<{isMobile: boolean}>`
    display: flex;
    flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
    justify-content: space-around;
 
`
